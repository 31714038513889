import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import './AnimatedContent.css';

const AnimatedContent = ({ content, animationType, onAnimationComplete }) => {
  const [visibleChars, setVisibleChars] = useState(animationType === 'stream' ? 0 : content.length);
  const [isAnimating, setIsAnimating] = useState(animationType === 'stream');

  useEffect(() => {
    if (animationType === 'stream') {
      setVisibleChars(0);
      setIsAnimating(true);
    } else if (animationType === 'slide') {
      setIsAnimating(true);
    } else {
      setIsAnimating(false);
    }
  }, [animationType, content]);

  useEffect(() => {
    if (isAnimating && animationType === 'stream') {
      const interval = setInterval(() => {
        setVisibleChars((prevChars) => {
          const nextChars = prevChars + 50;
          if (nextChars >= content.length) {
            clearInterval(interval);
            if (onAnimationComplete) onAnimationComplete();
          }
          return nextChars;
        });
      }, 4);
  
      return () => clearInterval(interval);
    }
  }, [isAnimating, animationType, content, onAnimationComplete]);
  

useEffect(() => {
  if (!isAnimating && onAnimationComplete) {
    setTimeout(() => {
      onAnimationComplete();
    }, 0); 
  }
}, [isAnimating, onAnimationComplete]);

  return (
    <div className={`animated-content ${animationType}`}>
      {animationType === 'stream' ? (
        <div>
          <ReactMarkdown>
            {content.slice(0, visibleChars)}
          </ReactMarkdown>
          {isAnimating && (
            <>
              <ReactMarkdown></ReactMarkdown>
              <span className="typing-cursor solid" />
            </>
          )}
        </div>
      ) : (
        <ReactMarkdown>{content}</ReactMarkdown>
      )}
    </div>
  );
};

export default AnimatedContent;