import React, { useEffect } from 'react';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { ConversationProvider } from './contexts/ConversationContext';
import ChatbotWidget from './ChatbotWidget';

const AppContent = () => {
  const { refreshAuth } = useAuth();

  useEffect(() => {
    const messageHandler = (event) => {
      if (event.data.type === 'REFRESH_AUTH') {
        refreshAuth();
      }
    };

    window.addEventListener('message', messageHandler);

    return () => {
      window.removeEventListener('message', messageHandler);
    };
  }, [refreshAuth]);

  return <ChatbotWidget />;
};

const App = () => {
  return (
    <AuthProvider>
      <ConversationProvider>
        <AppContent />
      </ConversationProvider>
    </AuthProvider>
  );
};

export default App;