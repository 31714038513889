import { useEffect, useRef, useState, useCallback } from 'react';

const DEBOUNCE_DELAY = 500; // milliseconds
const LOAD_COOLDOWN = 2000; // milliseconds

const useScrollAndLoad = (messages, isLoadingOlderMessages, loadMoreMessages, isScrolledToBottom, setIsScrolledToBottom, isOpen, messageToStream) => {
  const messagesListRef = useRef(null);
  const lastLoadTime = useRef(0);
  const debounceTimer = useRef(null);

  const debouncedLoadMore = useCallback(() => {
    const now = Date.now();
    if (now - lastLoadTime.current > LOAD_COOLDOWN) {
      loadMoreMessages();
      lastLoadTime.current = now;
    }
  }, [loadMoreMessages]);

  const handleScroll = useCallback(() => {
    const list = messagesListRef.current;
    if (!list) return;

    const scrollHeight = list.scrollHeight - list.clientHeight;
    const scrollPosition = list.scrollTop;

    if (scrollHeight - Math.abs(scrollPosition) <= 50 && !isLoadingOlderMessages) {
      if (debounceTimer.current) {
        clearTimeout(debounceTimer.current);
      }
      debounceTimer.current = setTimeout(debouncedLoadMore, DEBOUNCE_DELAY);
    } else if (scrollHeight - scrollPosition <= 50) {
      setIsScrolledToBottom(true);
    } else {
      setIsScrolledToBottom(false);
    }
  }, [isLoadingOlderMessages, setIsScrolledToBottom, debouncedLoadMore]);

  useEffect(() => {
    const list = messagesListRef.current;
    if (list) {
      list.addEventListener('scroll', handleScroll);
      return () => list.removeEventListener('scroll', handleScroll);
    }
  }, [isOpen, handleScroll]);

  useEffect(() => {
    const list = messagesListRef.current;
    if (list && isLoadingOlderMessages && !isScrolledToBottom) {
      const scrollHeight = list.scrollHeight;
      const scrollPosition = list.scrollTop;
      const scrollBottom = scrollHeight - scrollPosition;
      return () => {
        list.scrollTop = list.scrollHeight - scrollBottom;
      };
    }
  }, [isLoadingOlderMessages, isScrolledToBottom]);

  return messagesListRef;
};

export default useScrollAndLoad;