import { createRoot } from 'react-dom/client';
import React from 'react';
import App from './App';
import './styles/global.css';

const container = document.getElementById('root');
if (!container) throw new Error('Failed to find the root container');

const root = createRoot(container);
root.render(<App />);

if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default;
    root.render(<NextApp />);
  });
}