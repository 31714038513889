import React from "react";
import closeWidget from "../../assets/close-widget-icon.svg";

import "./Header.css";

const Header = ({ isOpen, toggleChat }) => {
  return (
    <div className="chat-header">
      <div className="company-logo-container">
      </div>
      {isOpen && (
        <button onClick={toggleChat} className="chat-close-button">
          <img src={closeWidget} alt="Close Widget" />
        </button>
      )}
    </div>
  );
};

export default Header;
