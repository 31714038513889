import React from 'react';
import './LoadingSkeleton.css'; 

const LoadingSkeleton = () => {
  return (
    <div className="blobs">
      <svg>
      </svg>
      <div className="blob-center"></div>
      {[...Array(6)].map((e, i) => (
        <div key={i} className={`blob blob-${i + 1}`}></div>
      ))}
    </div>
  );
};

export default LoadingSkeleton;
