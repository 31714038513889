import React, { createContext, useState, useEffect, useContext } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data.type === 'INITIALIZE' || event.data.type === 'SET_USER_DATA') {
        setUser(event.data.userData);
        setLoading(false);
      } else if (event.data.type === 'CLEAR_USER_DATA') {
        setUser(null);
        setLoading(false);
      }
    };

    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, []);

  const refreshAuth = () => {
    window.parent.postMessage({ type: 'REFRESH_AUTH' }, '*');
  };

  return (
    <AuthContext.Provider value={{ user, loading, refreshAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);