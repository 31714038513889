import axios from 'axios';

const API_BASE_URL = process.env.API_BASE_URL;

const api = axios.create({
  baseURL: API_BASE_URL,
});

export const getMessages = (token, organizationId, agentId, userId, nextPageToken) => {
  return api.get(`/api/v1/organizations/${organizationId}/agents/${agentId}/users/${userId}/messages`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: { start_after_token: nextPageToken },
  });
};

export const getUser = (token, organizationId, agentId, userId) => {
  return api.get(`/api/v1/organizations/${organizationId}/agents/${agentId}/users/${userId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const sendMessageToChat = (token, organizationId, agentId, userId, messageData) => {
  return api.post(`/api/v1/organizations/${organizationId}/agents/${agentId}/user/${userId}/chat`, messageData, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
